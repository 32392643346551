import React, { useEffect, useMemo, useState } from "react";
import ReadOnlyField from "v3/Components/Form/ReadOnlyField";
import { FileUploader } from "v3/Components/Form/FileUploader";
import { CONTROLLERS } from "Models/Templates/Routes/Routes";
import { useIdParam } from "Utilities";
import { TitleActive } from "v3/Components/Form/TitleActive";
import { TitleReadOnly } from "v3/Components/Form/TitleReadOnly";
import { InputActive } from "v3/Components/Form/InputActive";
import { FilePondFile } from "filepond";
import './AddEditReferralsRequest.AdditionalInformation.scss';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { FieldError } from "v3/Components/Form/FormSection";
import { ReferralRequestSchema, useReferralRequestForm } from "./useReferralRequestForm";
import './AddEditReferralsRequest.ReportDetails.scss';
import Dropdown, { DropdownValue } from "v3/Components/Form/Dropdown";
import { useEnums } from "Context";
import { DatePickerInput } from "v3/Components/Form/DateTimePickers/DatePicker";
import { formatDate } from "@telerik/kendo-intl";
import { DateTimePickerInput } from "v3/Components/Form/DateTimePickers/DateTimePicker";

type ReportDetailsProps = {
  form: ReturnType<typeof useReferralRequestForm>;
  fieldErrors?: FieldError[];
}

export const ReportDetailsActive: React.FC<ReportDetailsProps> = ({
  form,
  fieldErrors
}) => {
  const [id, isAdd, isEdit] = useIdParam();
  const { specialty, tatPriority, state, apContact } = useEnums();

  return (
    <div className="">
      <TitleActive>Date</TitleActive>
      <div className="active-row">
        <div className="active-fields">
          <DateTimePickerInput
            label={'Due Date & Time'}
            value={form.getFieldProps("dueDate").value}
            required
            onChange={(value: any) => {
              const date = new Date(value);
              form.setFieldValue("dueDate", date)
            }}
          />
          <Dropdown
            label="TAT (Turn Around Time)"
            hint="Select an option"
            required
            value={form.getFieldProps("tat").value}
            onChange={(value) => form.setFieldValue("tat", value)}
            options={[
              { displayName: "TAT1", value: "TAT1" },
              { displayName: "TAT2", value: "TAT2" },
              { displayName: "TAT3", value: "TAT3" },
              { displayName: "TAT4", value: "TAT4" },
              { displayName: "TAT5", value: "TAT5" }
            ]}
          />
          <Dropdown
            label="TAT Priority"
            hint="Select an option"
            required
            value={form.getFieldProps("tatPriority").value}
            onChange={(value) => form.setFieldValue("tatPriority", value)}
            options={[]}
            enums={tatPriority}
          />
        </div>
      </div>

      <TitleActive>Requirements</TitleActive>
      <div className="active-row">
        <div className="active-fields">
          <Dropdown
            label="Jurisdictional Match Required"
            hint="Select an option"
            required
            value={form.getFieldProps("jurisdictionalMatch").value}
            onChange={(value) => form.setFieldValue("jurisdictionalMatch", value)}
            options={[
              { displayName: "Yes", value: 1 },
              { displayName: "No", value: 0 }
            ]}
          />
          <Dropdown
            label="Benefit State"
            hint="Select an option"
            required
            value={form.getFieldProps("benefitState").value}
            onChange={(value) => form.setFieldValue("benefitState", value)}
            options={[]}
            enums={state}
            valueAsDisplayName
          />
          <Dropdown
            label="Requested Reviewer Specialty"
            hint="Select an option"
            required
            value={form.getFieldProps("requestedReviewerSpecialty").value}
            onChange={(value) => form.setFieldValue("requestedReviewerSpecialty", value)}
            options={[]}
            enums={specialty}
          />
        </div>
      </div>
      <div className="active-row">
        <div className="active-fields">
          <Dropdown
            label="Service"
            hint="Select an option"
            required
            value={form.getFieldProps("service").value}
            onChange={(value) => form.setFieldValue("service", value)}
            options={[
              { displayName: "Service1", value: "Service1" },
              { displayName: "Service2", value: "Service2" },
              { displayName: "Service3", value: "Service3" },
              { displayName: "Service4", value: "Service4" },
              { displayName: "Service5", value: "Service5" }
            ]}
          />
          <Dropdown
            label="Sub-Service"
            hint="Select an option"
            required
            value={form.getFieldProps("subService").value}
            onChange={(value) => form.setFieldValue("subService", value)}
            options={[
              { displayName: "Sub-Service 1", value: "Sub-Service1" },
              { displayName: "Sub-Service 2", value: "Sub-Service2" },
              { displayName: "Sub-Service 3", value: "Sub-Service3" },
              { displayName: "Sub-Service 4", value: "Sub-Service4" },
              { displayName: "Sub-Service 5", value: "Sub-Service5" }
            ]}
          />
          <div className="input-container"></div>
        </div>
      </div>

      <TitleActive>Attending Provider(s)</TitleActive>
      <div className="active-row">
        <div className="active-fields">
          <Dropdown
            label="AP Contact Preference"
            hint="Select an option"
            required
            value={form.getFieldProps("apContact").value}
            onChange={(value) => form.setFieldValue("apContact", value)}
            options={[]}
            enums={apContact}
          />
          <div className="input-container"></div>
          <div className="input-container"></div>
        </div>
      </div>

      <TitleActive>Attending Provider Information</TitleActive>
      <div className="active-row">
        <div className="active-fields">
          <InputActive
            id="apName"
            name="apName"
            label="AP Name"
            value={form.getFieldProps("apName").value ?? ""}
            onChange={(e: any) => {
              form.setFieldValue("apName", e.target.value)
            }}
            error={fieldErrors?.find(f => f.fieldId === "apName")?.error}
          />
          <Dropdown
            label="AP Specialty"
            hint="Select an option"
            value={form.getFieldProps("apSpecialty").value}
            onChange={(value) => form.setFieldValue("apSpecialty", value)}
            options={[]}
            enums={specialty}
          />
          <InputActive
            id="apPhoneNumber"
            name="apPhoneNumber"
            label="AP Phone Number"
            value={form.getFieldProps("apPhoneNumber").value ?? ""}
            onChange={(e: any) => {
              form.setFieldValue("apPhoneNumber", e.target.value)
            }}
            error={fieldErrors?.find(f => f.fieldId === "apPhoneNumber")?.error}
          />
          <InputActive
            id="apFaxOrEmail"
            name="apFaxOrEmail"
            label="AP Fax or Email"
            value={form.getFieldProps("apFaxOrEmail").value ?? ""}
            onChange={(e: any) => {
              form.setFieldValue("apFaxOrEmail", e.target.value)
            }}
            error={fieldErrors?.find(f => f.fieldId === "apFaxOrEmail")?.error}
          />
        </div>
      </div>
    </div>
  );
}

export const ReportDetailsReadOnly: React.FC<ReportDetailsProps> = ({
  form
}) => {
  return (
    <div className="read-only-content">
      <TitleReadOnly>Date</TitleReadOnly>
      <ReadOnlyField
        id="dueDate"
        label="Due Date & Time"
        value={formatDate(form.getFieldProps('dueDate').value, 'd')}
        schema={ReferralRequestSchema}
      />
      <ReadOnlyField
        id="tat"
        label="TAT (Turn Around Time)"
        value={form.getFieldProps('tat').value?.displayName ?? ''}
        schema={ReferralRequestSchema}
      />
      <ReadOnlyField
        id="tatPriority"
        label="TAT Priority"
        value={form.getFieldProps('tatPriority').value?.displayName ?? ''}
        schema={ReferralRequestSchema}
      />
      <TitleReadOnly>Requirements</TitleReadOnly>
      <ReadOnlyField
        id="jurisdictionalMatch"
        label="Jurisdictional Match Required"
        value={form.getFieldProps('jurisdictionalMatch').value?.displayName ?? ''}
        schema={ReferralRequestSchema}
      />
      <ReadOnlyField
        id="benefitState"
        label="Benefit State"
        value={form.getFieldProps('benefitState').value?.displayName ?? ''}
        schema={ReferralRequestSchema}
      />
      <ReadOnlyField
        id="requestedReviewerSpecialty"
        label="Requested Reviewer Specialty"
        value={form.getFieldProps('requestedReviewerSpecialty').value?.displayName ?? ''}
        schema={ReferralRequestSchema}
      />
      <ReadOnlyField
        id="service"
        label="Service"
        value={form.getFieldProps('service').value?.displayName ?? ''}
        schema={ReferralRequestSchema}
      />
      <ReadOnlyField
        id="subService"
        label="subService"
        value={form.getFieldProps('subService').value?.displayName ?? ''}
        schema={ReferralRequestSchema}
      />
      <TitleReadOnly>Attending Provider(s)</TitleReadOnly>
      <ReadOnlyField
        id="apContact"
        label="AP Contact Preference"
        value={form.getFieldProps('apContact').value?.displayName ?? ''}
        schema={ReferralRequestSchema}
      />
      <TitleReadOnly>Attending Provider Information</TitleReadOnly>
      <ReadOnlyField
        id="apName"
        label="AP Name"
        value={form.getFieldProps('apName').value ?? ''}
        schema={ReferralRequestSchema}
      />
      <ReadOnlyField
        id="apSpecialty"
        label="AP Specialty"
        value={form.getFieldProps('apSpecialty').value?.displayName ?? ''}
        schema={ReferralRequestSchema}
      />
      <ReadOnlyField
        id="apPhoneNumber"
        label="AP Phone Number"
        value={form.getFieldProps('apPhoneNumber').value ?? ''}
        schema={ReferralRequestSchema}
      />
      <ReadOnlyField
        id="apFaxOrEmail"
        label="AP Fax or Email"
        value={form.getFieldProps('apFaxOrEmail').value ?? ''}
        schema={ReferralRequestSchema}
      />
    </div>
  )
}