import React, { useState } from 'react';
import './ReplicableComponent.scss';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type ReplicableComponentProps = {
  children: (props: {
    index: number;
    data: any;
    onDuplicate: () => void;
    onChange: (value: any, formPropsName: string) => void;
  }) => JSX.Element;
  initialData?: any;
  form: any;
  label: string;
  formPropsToClearOnRemoval?: string[];
};

export const ReplicableComponent = ({
                                      children,
                                      initialData = {},
                                      form,
                                      label,
                                      formPropsToClearOnRemoval
                                    }: ReplicableComponentProps) => {
  const [components, setComponents] = useState<Array<{ data: any }>>([
    { data: initialData }
  ]);

  const handleDuplicate = (index: number) => {
    setComponents(prev => [
      ...prev.slice(0, index + 1),
      { data: { ...initialData } },
      ...prev.slice(index + 1)
    ]);
  };

  const handleChange = (index: number, value: any, formPropsName: string) => {
    const oldArray = form.getFieldProps(formPropsName).value || [];
    oldArray[index] = { ...oldArray[index], ...value };
    form.setFieldValue(formPropsName, oldArray);
    setComponents(prev => prev.map((comp, i) => (i === index ? { data: value } : comp)));
  };

  const handleRemove = (index: number) => {
    formPropsToClearOnRemoval?.forEach(formProp => {
      const oldArray = form.getFieldProps(formProp).value;
      if (oldArray) {
        oldArray.splice(index, 1);
        form.setFieldValue(formProp, oldArray);
      }
    });
    setComponents(prev => prev.filter((_, i) => i !== index));
  };

  return (
    <div className="replicable-container">
      {components.map((comp, index) => (
        <div key={index} className="replicable-item">
          <div className="replicable-item-content">
            {children({
              index,
              data: comp.data,
              onDuplicate: () => handleDuplicate(index),
              onChange: (value, formPropsName) => handleChange(index, value, formPropsName),
            })}
            <div className={"red-x-icon"}>
              {index >= 1 && (
                <FontAwesomeIcon onClick={() => handleRemove(index)} icon={faTimes} size="lg" style={{ marginLeft: "15px"}}/>
              )}
            </div>
          </div>
          <div className={"add-component"}>
            {index === components.length - 1 && (
              <p onClick={() => handleDuplicate(index)} style={{ marginTop: '12px', color: '#3498DB' }}>
                + Add {label}
              </p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};