import { DropdownValue } from 'v3/Components/Form/Dropdown';

export type ReferralRequestDraftDocument = {
  fileId: string;
  fileName: string;
  size: string;
};

export class ReferralsRequest {
  id?: string;
  skipValidation?: boolean;
  attorneyName?: string;
  attorneyPrimaryContact?: string;
  attorneyAdditionalContact?: string;
  referralSourceId?: string;
  referralDate?: Date;
  firstName?: string;
  lastName?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  phoneNumber?: string;
  email?: string;
  specialInstructions?: string;
  gender?: DropdownValue;
  referralRequestDraftDocuments?: ReferralRequestDraftDocument[];
  dateOfBirth?: Date;
  dueDate?: Date;
  tat?: DropdownValue;
  tatPriority?: DropdownValue;
  juristictionalMatch?: DropdownValue;
  benefitState?: DropdownValue;
  requestedReviewerSpecialty?: DropdownValue;
  service?: DropdownValue;
  subService?: DropdownValue;
  apContact?: DropdownValue;
  apName?: string;
  apSpecialty?: DropdownValue;
  apPhoneNumber?: string;
  apFaxOrEmail?: string;
  clientNumber?: string;
  referralNumber?: string;
  dateOfInjury?: Date;
  dateOfRequest?: Date;
  dateOfRequestDuplicate?: Date;
  dateOfInjuryOrDisability?: Date;
  employerName?: string;
  employerLocation?: string;
  employerZipCode?: string;
  medicationName?: string;
  medicationDetails?: string;
};