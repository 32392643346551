import React, { HTMLProps, ReactNode, useState } from "react";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import './FormSection.scss';
import { Clear } from "@mui/icons-material";

export interface FieldError {
  fieldId: string;
  error: string;
}

type FormSectionProps = HTMLProps<HTMLDivElement> & {
  title: string;
  readOnlyContent?: ReactNode;
  activeContent?: ReactNode;
  initialContent?: ReactNode;
  isActive?: boolean;
  optional?: boolean;
  completed?: boolean;
  initializedByDefault?: boolean;
  onActive: () => void;
  initialIcon?: ReactNode;
  initialText?: string;
  onRemoveSection?: () => void;
};

const FormSection: React.FC<FormSectionProps> = ({
  title,
  readOnlyContent,
  activeContent,
  initialContent,
  isActive,
  optional,
  completed,
  initializedByDefault,
  onActive,
  initialIcon,
  initialText,
  onRemoveSection
}) => {
  const [initialized, setInitialized] = useState(initializedByDefault ?? false);

  const id = title.replace(/\s+/g, "").toLowerCase();

  return (
    <div
      id={id}
      className={`section ${!isActive && !initialized && "initial-state"}`}
      onClick={() => {
        if (isActive || initialized) return;
        onActive();
        setInitialized(true);
      }}>
      <div className="header">
        {isActive && (
          <div className="active-indicator"></div>
        )}
        <span className={`title ${isActive && "title-active"}`}>
          {title}
        </span>
        {optional && (
          <span className="optional">{"(Optional)"}</span>
        )}
        {!isActive && initialized && (
          <EditOutlinedIcon onClick={onActive} className="edit-icon" />
        )}
        {onRemoveSection && (
          <div className="remove-icon" onClick={onRemoveSection}>
            Remove
            <Clear
              className='x-icon'
            />
          </div>
        )}
        {completed && (
          <div className="check-icon">
            <CheckRoundedIcon />
          </div>
        )}
      </div>
      <div className="body">
        {isActive ? (
          <div className="active-content">
            {activeContent}
          </div>
        ) : initialized ? (
          <div className="read-only-content">
            {readOnlyContent}
          </div>
        ) : (
          <div className="initial-content">
            <div className="icon-container">
              {initialIcon}
            </div>
            <p>{initialText}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default FormSection;