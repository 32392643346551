import React, { useEffect, useMemo, useState } from 'react';
import ReadOnlyField from 'v3/Components/Form/ReadOnlyField';
import { FileUploader } from 'v3/Components/Form/FileUploader';
import { CONTROLLERS } from 'Models/Templates/Routes/Routes';
import { useIdParam } from 'Utilities';
import { TitleActive } from 'v3/Components/Form/TitleActive';
import { TitleReadOnly } from 'v3/Components/Form/TitleReadOnly';
import { InputActive } from 'v3/Components/Form/InputActive';
import { FilePondFile } from 'filepond';
import './AddEditReferralsRequest.AdditionalInformation.scss';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { FieldError } from 'v3/Components/Form/FormSection';
import {
  ReferralRequestSchema,
  useReferralRequestForm,
} from './useReferralRequestForm';
import './AddEditReferralsRequest.ClaimDetails.scss';
import Dropdown, { DropdownValue } from 'v3/Components/Form/Dropdown';
import { useEnums } from 'Context';
import { DatePickerInput } from 'v3/Components/Form/DateTimePickers/DatePicker';
import { formatDate } from '@telerik/kendo-intl';

type ClaimDetailsProps = {
  form: ReturnType<typeof useReferralRequestForm>;
  fieldErrors?: FieldError[];
};

export const ClaimDetailsActive: React.FC<ClaimDetailsProps> = ({
  form,
  fieldErrors,
}) => {
  const [id, isAdd, isEdit] = useIdParam();
  const { gender, state } = useEnums();

  return (
    <div className="">
      <TitleActive>Claim Details</TitleActive>
      <div className="active-row">
        <div className="active-fields">
          <InputActive
            id="clientNumber"
            name="clientNumber"
            label="Claim/Client Number"
            value={form.getFieldProps('clientNumber').value ?? ''}
            onChange={(e: any) => {
              form.setFieldValue('clientNumber', e.target.value);
            }}
            required
            error={
              fieldErrors?.find((f) => f.fieldId === 'clientNumber')?.error
            }
          />
          <InputActive
            id="referralNumber"
            name="referralNumber"
            label="Referral Number"
            value={form.getFieldProps('referralNumber').value ?? ''}
            onChange={(e: any) => {
              form.setFieldValue('referralNumber', e.target.value);
            }}
            error={
              fieldErrors?.find((f) => f.fieldId === 'referralNumber')?.error
            }
          />

          <DatePickerInput
            label={'Date of Injury'}
            value={form.getFieldProps('dateOfInjury').value}
            required
            onChange={(value) =>
              form.setFieldValue('dateOfInjury', value.value)
            }
          />
        </div>
      </div>
      <div className="active-row">
        <div className="active-fields">
          <DatePickerInput
            label={'Date of Request'}
            value={form.getFieldProps('dateOfRequest').value}
            required
            onChange={(value) =>
              form.setFieldValue('dateOfRequest', value.value)
            }
          />
          <DatePickerInput
            label={'Date of Request'}
            value={form.getFieldProps('dateOfRequestDuplicate').value}
            onChange={(value) =>
              form.setFieldValue('dateOfRequestDuplicate', value.value)
            }
          />
          <DatePickerInput
            label={'Date of Injury/Disability'}
            value={form.getFieldProps('dateOfInjuryOrDisability').value}
            onChange={(value) =>
              form.setFieldValue('dateOfInjuryOrDisability', value.value)
            }
          />
        </div>
      </div>

      <TitleActive>Employer Information</TitleActive>
      <div className="active-row">
        <div className="active-fields">
          <InputActive
            id="employerName"
            name="employerName"
            label="Employer Name"
            value={form.getFieldProps('employerName').value ?? ''}
            onChange={(e: any) => {
              form.setFieldValue('employerName', e.target.value);
            }}
            error={
              fieldErrors?.find((f) => f.fieldId === 'employerName')?.error
            }
            className="input-container street-address"
          />
          <Dropdown
            label="Employer Location"
            value={form.getFieldProps('employerLocation').value}
            onChange={(value) => form.setFieldValue('employerLocation', value)}
            options={[]}
            enums={state}
            valueAsDisplayName
          />
          <InputActive
            id="employerZipCode"
            name="employerZipCode"
            label="Employer Zip Code"
            value={form.getFieldProps('employerZipCode').value ?? ''}
            onChange={(e: any) => {
              form.setFieldValue('employerZipCode', e.target.value);
            }}
            error={fieldErrors?.find((f) => f.fieldId === 'zipCode')?.error}
          />
        </div>
      </div>

      <TitleActive>Medication Details</TitleActive>
      <div className="active-row">
        <div
          className="active-fields"
          style={{ justifyContent: 'space-between' }}
        >
          <div style={{ width: '31%' }}>
            <InputActive
              id="medicationName"
              name="medicationName"
              label="Medication Name"
              value={form.getFieldProps('medicationName').value ?? ''}
              onChange={(e: any) => {
                form.setFieldValue('medicationName', e.target.value);
              }}
              required
              error={
                fieldErrors?.find((f) => f.fieldId === 'medicationName')?.error
              }
            />
          </div>
          <div style={{ width: '66%' }}>
            <InputActive
              id="medicationDetails"
              name="medicationDetails"
              label="Medication Details"
              value={form.getFieldProps('medicationDetails').value ?? ''}
              onChange={(e: any) => {
                form.setFieldValue('medicationDetails', e.target.value);
              }}
              required
              error={
                fieldErrors?.find((f) => f.fieldId === 'medicationDetails')
                  ?.error
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const ClaimDetailsReadOnly: React.FC<ClaimDetailsProps> = ({ form }) => {
  return (
    <div className="read-only-content">
      <TitleReadOnly>Claimant Identity</TitleReadOnly>
      <ReadOnlyField
        id="clientNumber"
        label="Claim/Client Number"
        value={form.getFieldProps('clientNumber').value ?? ''}
        schema={ReferralRequestSchema}
      />
      <ReadOnlyField
        id="referralNumber"
        label="Referral Number"
        value={form.getFieldProps('referralNumber').value ?? ''}
        schema={ReferralRequestSchema}
      />
      <ReadOnlyField
        id="dateOfInjury"
        label="Date of Injury"
        value={formatDate(form.getFieldProps('dateOfInjury').value, 'd')}
        schema={ReferralRequestSchema}
      />
      <ReadOnlyField
        id="dateOfRequest"
        label="Date of Request"
        value={formatDate(form.getFieldProps('dateOfRequest').value, 'd')}
        schema={ReferralRequestSchema}
      />
      <ReadOnlyField
        id="dateOfRequestDuplicate"
        label="Date of Request"
        value={formatDate(
          form.getFieldProps('dateOfRequestDuplicate').value,
          'd'
        )}
        schema={ReferralRequestSchema}
      />
      <ReadOnlyField
        id="dateOfInjuryOrDisability"
        label="Date of Injury/Disability"
        value={formatDate(
          form.getFieldProps('dateOfInjuryOrDisability').value,
          'd'
        )}
        schema={ReferralRequestSchema}
      />
      <TitleReadOnly>Employer Information</TitleReadOnly>
      <ReadOnlyField
        id="employerName"
        label="Employer Name"
        value={form.getFieldProps('employerName').value ?? ''}
        schema={ReferralRequestSchema}
      />
      <ReadOnlyField
        id="employerLocation"
        label="Employer Location"
        value={form.getFieldProps('employerLocation.label').value ?? ''}
        schema={ReferralRequestSchema}
      />
      <ReadOnlyField
        id="employerZipCode"
        label="Employer Zip Code"
        value={form.getFieldProps('employerZipCode').value ?? ''}
        schema={ReferralRequestSchema}
      />
      <TitleReadOnly>Medication Details</TitleReadOnly>
      <ReadOnlyField
        id="medicationName"
        label="Medication Name"
        value={form.getFieldProps('medicationName').value ?? ''}
        schema={ReferralRequestSchema}
      />
      <ReadOnlyField
        id="medicationDetails"
        label="Medication Details"
        value={form.getFieldProps('medicationDetails').value ?? ''}
        schema={ReferralRequestSchema}
      />
    </div>
  );
};
