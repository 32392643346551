import './AddEditClient.ClientSetup.scss'
import React, { useEffect } from "react";
import { useClientForm, ClientSchema } from "./useClientForm";
import ReadOnlyField from "v3/Components/Form/ReadOnlyField";
import { Clear } from '@mui/icons-material';
import { TitleActive } from 'v3/Components/Form/TitleActive';
import { TitleReadOnly } from 'v3/Components/Form/TitleReadOnly';
import { InputActive } from 'v3/Components/Form/InputActive';
import AddIcon from '@mui/icons-material/Add';
import { FieldError } from 'v3/Components/Form/FormSection';
import { v4 as uuidv4 } from 'uuid';
import Dropdown, { DropdownDbOption } from 'v3/Components/Form/Dropdown';
import { useEnums } from 'Context';
import { useDataFetcher } from 'Utilities';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import MultiSelectDropdown from 'v3/Components/Form/MultiSelectDropdown';

type ClientSetupProps = {
  form: ReturnType<typeof useClientForm>;
  fieldErrors?: FieldError[];
  index: number;
}

export const ClientSetupActive: React.FC<ClientSetupProps> = ({
  form,
  fieldErrors,
  index
}) => {
  const { lineOfBusiness } = useEnums()
  const [services, servicesStatus] = useDataFetcher<DropdownDbOption[]>(
    `${ENDPOINTS.users.base}/servicesByLineOfBusiness?lineOfBusiness=${form.values.lineOfBusinesses?.[index]?.lineOfBusiness?.value}`,
    [],
    (d) => d.data,
    undefined,
    true,
    "3.0"
  );

  // user data fetcher to get subservices by lineofbusiness
  const [subServices, subServicesStatus] = useDataFetcher<DropdownDbOption[]>(
    `${ENDPOINTS.users.base}/subServicesByLineOfBusiness?lineOfBusiness=${form.values.lineOfBusinesses?.[index]?.lineOfBusiness?.value}`,
    [],
    (d) => d.data,
    undefined,
    true,
    "3.0"
  );

  const addQuestionRow = () => {
    form.setFieldValue(`lineOfBusinesses[${index}].reportQuestions`, [
      ...((form.values.lineOfBusinesses?.[index]?.reportQuestions) ?? []),
      {
        question: "",
        id: uuidv4()
      }
    ]);
  };

  useEffect(() => {
    const questions = form.values.lineOfBusinesses?.[index]?.reportQuestions;
    if (!questions || questions.length === 0) {
      addQuestionRow();
    }
  }, [])

  return (
    <div className="">
      <TitleActive>Client Configuration</TitleActive>
      <div className="active-row">
        <div className="active-fields">
          <Dropdown
            label="Line of Business"
            hint="Select an option"
            required
            value={form.getFieldProps(`lineOfBusinesses[${index}].lineOfBusiness`).value}
            onChange={(value) => {
              form.setFieldValue(`lineOfBusinesses[${index}].service`, {})
              form.setFieldValue(`lineOfBusinesses[${index}].subServices`, [])
              form.setFieldValue(`lineOfBusinesses[${index}].lineOfBusiness`, value)
            }}
            enums={lineOfBusiness}
            error={fieldErrors?.find(f => f.fieldId === `lineOfBusinesses[${index}].LineOfBusiness`)?.error}
          />
          <Dropdown
            label="Service"
            hint="Select an option"
            required
            value={form.getFieldProps(`lineOfBusinesses[${index}].service`).value}
            onChange={(value) => {
              form.setFieldValue(`lineOfBusinesses[${index}].service`, value)
            }}
            dbOptions={servicesStatus === "success" ? services : []}
            error={fieldErrors?.find(f => f.fieldId === `lineOfBusinesses[${index}].Service.Id`)?.error}
          />
          <MultiSelectDropdown
            label="Sub Service"
            hint="Select an option"
            required
            values={form.getFieldProps(`lineOfBusinesses[${index}].subServices`).value}
            onChange={(value) => form.setFieldValue(`lineOfBusinesses[${index}].subServices`, value)}
            dbOptions={subServicesStatus === "success" ? subServices : []}
          />
        </div>
      </div>
      <div className='report-questions'>
        <TitleActive>Report Questions</TitleActive>
        {form.values.lineOfBusinesses?.[index]?.reportQuestions?.map((question, i) => (
          <ReportQuestion
            key={`lineOfBusinesses[${index}].reportQuestions[${i}]`}
            uniqueId={`lineOfBusinesses[${index}].reportQuestions[${i}]`}
            lobIndex={index}
            index={i}
            form={form}
            fieldErrors={fieldErrors}
          />
        ))}
        <div
          className='add-contact-button'
          onClick={() => addQuestionRow()}
          style={{
            marginBottom: 40
          }}
        >
          <AddIcon />
          <span>Add Question</span>
        </div>
      </div>
    </div>
  );
}

const ReportQuestion = ({
  uniqueId,
  index,
  form,
  fieldErrors,
  lobIndex
}: {
  uniqueId: string;
  index: number;
  form: ReturnType<typeof useClientForm>;
  fieldErrors?: FieldError[];
  lobIndex: number;
}) => {
  return (
    <div key={index} className="contact-row">
      <div className='contact-fields'>
        <InputActive
          id={`${uniqueId}`}
          label="Question"
          value={form.getFieldProps(`${uniqueId}`).value.question ?? ""}
          onChange={(e: any) => {
            form.setFieldValue(`${uniqueId}.question`, e.target.value)
          }}
          error={fieldErrors
            ?.find(f => f.fieldId === `${uniqueId}`)
            ?.error
          }
        />
      </div>
      {index > 0 ?
        (<Clear
          className='x-icon'
          onClick={() => {
            form.setFieldValue(`lineOfBusinesses[${lobIndex}].reportQuestions`, form.values.lineOfBusinesses?.[lobIndex]?.reportQuestions?.filter((_, i) => i !== index))
          }}
        />) : <div className='x-icon'></div>}
    </div>
  );
}

export const ClientSetupReadOnly: React.FC<ClientSetupProps> = ({
  form,
  index
}) => {
  return (
    <div className='report-questions-read'>
      <TitleReadOnly>Client Configuration</TitleReadOnly>
      <ReadOnlyField
        value={form.getFieldProps(`lineOfBusinesses[${index}].lineOfBusiness.displayName`).value}
        field={ClientSchema.fields.lineOfBusinesses?.innerType?.fields?.lineOfBusiness}
      />
      <ReadOnlyField
        value={form.getFieldProps(`lineOfBusinesses[${index}].service.name`).value}
        field={ClientSchema.fields.lineOfBusinesses?.innerType?.fields?.service}
      />
      <ReadOnlyField
        value={(form.getFieldProps(`lineOfBusinesses[${index}].subServices`).value)?.map((v: any) => v.name).join(", ")}
        field={ClientSchema.fields.lineOfBusinesses?.innerType?.fields?.subService}
      />
      <TitleReadOnly>Report Questions</TitleReadOnly>
      <ol>
        {form.values.lineOfBusinesses?.[index]?.reportQuestions?.map((question, i) => (
          <div
            key={i}
            className='contact-row'
          >
            <li key={`lineOfBusinesses[${index}].reportQuestions[${i}]`}>{question.question}</li>
          </div>
        ))}
      </ol>
    </div>
  )
}
