

export enum IDS {
  Id = "{id}",
  OptionalId = "{id?}",
  UserId = "{userId}",
  ClaimantId = "{claimantId}",
  ClaimId = "{claimId}",
  ReferralId = "{referralId}",
  GuidelineId = "{guidelineId}"
}

export enum CONTROLLERS {
  ControllerLiteral = "[controller]",
  ApiKeys = "apikeys",
  AppointmentAttachments = "appointmentattachments",
  Audits = "audits",
  ElmahErrors = "elmaherrors",
  Enumerations = "enumerations",
  ClosedCases = "closedcases",
  OpenCases = "opencases",
  Roles = "roles",
  Swagger = "swagger/v1",
  Users = "users",
  ManagedReviewers = "managedreviewers",
  Referrals = "referrals",
  Claimants = "claimants",
  CaseManagement = "casemanagement",
  Appointments = "appointments",
  AppointmentsCommercial = "appointmentsCommercial",
  Clients = "clients",
  Claims = "claims",
  ReferralDocuments = "referraldocuments",
  ReferralRawDocuments = "referralrawdocuments",
  Guidelines = "guidelines",
  ReferralsRequests = "referralsRequests",
  ReferralsRequestsDraft = "referralsrequestsdraft",
  ReferralsRequestsCommercial = "referralsRequestsCommercial",
  AppointmentDocuments = "appointmentdocuments",
  AppointmentReports = "appointmentreports",
  AppointmentReportsTemplates = "appointmentreportstemplates",
  AppointmentReportsBundles = "appointmentsreportsbundles",
  EvalTypes = "evaltypes",
  LineOfBusiness = "lineOfBusiness",
  Physicians = "physicians",
  Reviewers = "reviewers",
  ClaimEscalations = "claimescalations",
  ReviewerCredentialingForms = "reviewercredentialingforms",
  ReviewerLicenseDocuments = "reviewerLicenseDocuments",
  ReviewerBoardCertificationDocument = "reviewerBoardCertificationDocument",
  ReviewedReferrals = "reviewedreferrals",
  AuditQueue = "auditqueue",
  Notes = "notes",
  InternalNotes = "internalnotes",
  AssignedToWriters = "assignedtowriters",
  ReferralRequestDocuments = "referralrequestdocuments",
  AuditReport = "auditreport",
  Invoices = "invoices",
  MyInvoices = "myinvoices",
  Dashboard = "dashboard"
}

export enum METHODS {
  ActionLiteral = "[action]",
  READ = "Read",
  CREATE = "Create",
  GET = "Get",
  POST = "Post",
  PUT = "Put",
  DESTROY = "Destroy",
  UPDATE = "Update",
  GETINFOMODEL = "GetInfoModel",
  ADDEDIT = "AddEdit",
  EXPORT = "Export",
  REORDER = "Reorder",
  DROPDOWN_ITEMS = "DropdownItems",
  Upload = "Upload",
  Download = "Download",
  DeleteTempBlob = "DeleteTempBlob"
}

export enum URLS {
  Api = "api",
  ApiController = "api/[controller]",
  Default = "api/[controller]/[action]/{id?}",
  Validate = "validate",
  AddEdit = "{id?}/AddEdit",
  ActionIdLiteral = "[action]/{id}",
  ActionOptionalIdLiteral = "[action]/{id?}",
  IdActionLiteral = "{id}/[action]",
  ActionFilter = "[action]/{filter}",
  ApiKeys = "api/apikeys",
  AppointmentAttachments = "api/appointmentattachments",
  Audits = "api/audits",
  Users = "api/users",
  User = "api/users/{userId}",
  ManagedReviewers = "apimanagedreviewers",
  Referrals = "api/referrals",
  ViewReferral = "api/referrals/{referralId}",
  Claimants = "api/claimants",
  ViewClaimant = "api/claimants/{claimantId}",
  CaseManagement = "api/casemanagement",
  OpenCases = "api/casemanagement/OpenCases",
  ClosedCases = "api/casemanagement/ClosedCases",
  Appointments = "api/appointments",
  Clients = "api/clients",
  Claims = "api/claims",
  Guidelines = "api/guidelines",
  ReferralDocuments = "api/referraldocuments",
  ReferralRawDocuments = "api/referralrawdocuments",
  ReferralsRequests = "api/referralsRequests",
  AppointmentDocuments = "api/appointmentdocuments",
  AppointmentReports = "api/appointmentreports",
  AppointmentReportsTemplates = "api/appointmentreportstemplates",
  AppointmentReportsBundles = "api/appointmentsreportsbundles",
  Physicians = "api/physicians",
  Escalations = "api/claimescalations",
  Reviewers = "api/reviewers",
  ReviewerCredentialingForms = "api/reviewercredentialingforms",
  ReviewerLicenseDocument = "api/reviewerLicenseDocuments",
  ReviewerBoardCertificationDocument = "api/reviewerBoardCertificationDocument",
  ReviewedReferrals = "api/casemanagement/reviewedreferrals",
  AuditQueue = "api/auditqueue",
  Notes = "api/notes",
  InternalNotes = "api/internalnotes",
  AssignedToWriters = "api/casemanagement/assignedtowriters",
  ReferralRequestDocuments = "api/referralrequestdocuments",
  AuditReport = "api/auditreport",
  Invoices = "api/invoices",
  MyInvoices = "api/myinvoices",
  Dashboard = "api/dashboard"
}

export class Endpoint {
  constructor(controller: string) {
    this.base = controller;
    this.read = this.endpointString(METHODS.READ);
    this.create = this.endpointString(METHODS.CREATE);
    this.destroy = this.endpointString(METHODS.DESTROY);
    this.update = this.endpointString(METHODS.UPDATE);
    this.get = this.endpointString(METHODS.GET);
    this.dropdownItems = this.endpointString(METHODS.DROPDOWN_ITEMS);
    this.put = this.endpointString(METHODS.PUT);
    this.post = this.endpointString(METHODS.POST);
    this.getInfoModel = this.endpointString(METHODS.GETINFOMODEL);
  }

  endpointString(method: string) {
    return `${this.base}/${method}`;
  }

  readonly base: string;
  readonly read: string;
  readonly create: string;
  readonly destroy: string;
  readonly update: string;
  readonly get: string;
  readonly dropdownItems: string;
  readonly post: string;
  readonly put: string;
  readonly getInfoModel: string;
}

export const ENDPOINTS = {
  apikeys: new Endpoint(CONTROLLERS.ApiKeys),
  appointmentattachments: new Endpoint(CONTROLLERS.AppointmentAttachments),
  audits: new Endpoint(CONTROLLERS.Audits),
  elmaherrors: new Endpoint(CONTROLLERS.ElmahErrors),
  enumerations: new Endpoint(CONTROLLERS.Enumerations),
  closedcases: new Endpoint(CONTROLLERS.ClosedCases),
  opencases: new Endpoint(CONTROLLERS.OpenCases),
  roles: new Endpoint(CONTROLLERS.Roles),
  swaggerv1: new Endpoint(CONTROLLERS.Swagger),
  users: new Endpoint(CONTROLLERS.Users),
  managedreviewers: new Endpoint(CONTROLLERS.ManagedReviewers),
  referrals: new Endpoint(CONTROLLERS.Referrals),
  referralsCommercial: new Endpoint(CONTROLLERS.Referrals),
  claimants: new Endpoint(CONTROLLERS.Claimants),
  casemanagement: new Endpoint(CONTROLLERS.CaseManagement),
  appointments: new Endpoint(CONTROLLERS.Appointments),
  appointmentsCommercial: new Endpoint(CONTROLLERS.AppointmentsCommercial),
  clients: new Endpoint(CONTROLLERS.Clients),
  claims: new Endpoint(CONTROLLERS.Claims),
  referraldocuments: new Endpoint(CONTROLLERS.ReferralDocuments),
  referralrawdocuments: new Endpoint(CONTROLLERS.ReferralRawDocuments),
  guidelines: new Endpoint(CONTROLLERS.Guidelines),
  referralsRequests: new Endpoint(CONTROLLERS.ReferralsRequests),
  referralsRequestsDraft: new Endpoint(CONTROLLERS.ReferralsRequestsDraft),
  referralsRequestsCommercial: new Endpoint(CONTROLLERS.ReferralsRequestsCommercial),
  appointmentdocuments: new Endpoint(CONTROLLERS.AppointmentDocuments),
  appointmentreports: new Endpoint(CONTROLLERS.AppointmentReports),
  appointmentreportstemplates: new Endpoint(CONTROLLERS.AppointmentReportsTemplates),
  appointmentsreportsbundles: new Endpoint(CONTROLLERS.AppointmentReportsBundles),
  evaltypes: new Endpoint(CONTROLLERS.EvalTypes),
  lineOfBusiness: new Endpoint(CONTROLLERS.LineOfBusiness),
  physicians: new Endpoint(CONTROLLERS.Physicians),
  reviewers: new Endpoint(CONTROLLERS.Reviewers),
  claimescalations: new Endpoint(CONTROLLERS.ClaimEscalations),
  reviewercredentialingforms: new Endpoint(CONTROLLERS.ReviewerCredentialingForms),
  reviewerLicenseDocuments: new Endpoint(CONTROLLERS.ReviewerLicenseDocuments),
  reviewerBoardCertificationDocument: new Endpoint(CONTROLLERS.ReviewerBoardCertificationDocument),
  reviewedreferrals: new Endpoint(CONTROLLERS.ReviewedReferrals),
  auditqueue: new Endpoint(CONTROLLERS.AuditQueue),
  notes: new Endpoint(CONTROLLERS.Notes),
  internalnotes: new Endpoint(CONTROLLERS.InternalNotes),
  assignedtowriters: new Endpoint(CONTROLLERS.AssignedToWriters),
  referralrequestdocuments: new Endpoint(CONTROLLERS.ReferralRequestDocuments),
  auditreport: new Endpoint(CONTROLLERS.AuditReport),
  invoices: new Endpoint(CONTROLLERS.Invoices),
  myinvoices: new Endpoint(CONTROLLERS.MyInvoices),
  dashboard: new Endpoint(CONTROLLERS.Dashboard)
};
