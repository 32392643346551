import { Tenant } from 'Models';
import { EnumTypeSample } from 'Navigation';
import { EMPTY_GUID } from 'Utilities';
import { Permission } from './Templates/Permission/Permission';

export class RolePermission {
  id?: string;
  permission?: number;
  caseType?: EnumTypeSample;
  caseTypeNumber?: number;
}

export class Address {
  line1?: string;
  line2?: string;
  city?: string;
  state?: CoreEnum;
  zip?: string;
}

export class User {
  id?: string;
  login?: string;
  isAuthenticated?: string;
  emailAddress?: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  officeName?: string;
  rolePermissions?: RolePermission[];
  phoneNumber?: string;
  defaultCashDrawerId?: string;
  dailyCashDrawerId?: string;
  defaultCourtId?: string;
  signature?: string;
  pin?: string;
  requirePinForSignature?: boolean;
  hasSignature?: boolean;
  signatureRole?: NamedCoreEnum;
  displayName?: string;
  officeId?: string;
  isDoctor?: boolean;
  isNurse?: boolean;
  isDoctorAdmin?: boolean;
  isReviewer?: boolean;
  isAdmin?: boolean;
  isInternal?: boolean;
  isClient?: boolean;

  hasPermissions(
    permissionsList: Permission[],
    caseType: EnumTypeSample
  ): boolean {
    var filteredRolePermissions = this.rolePermissions?.filter(
      (x) =>
        x.permission &&
        permissionsList?.includes(x.permission) &&
        (!x.caseTypeNumber || x.caseTypeNumber === caseType)
    );

    return (
      !permissionsList ||
      permissionsList.length === 0 ||
      permissionsList.some(
        (p: Permission) =>
          filteredRolePermissions?.map((x) => x.permission).includes(p) || false
      )
    );
  }
}

export class Referral {
  referralSourceId?: string;
  dueDate?: Date;
  reviewed?: boolean;
  id?: string;
  clientId?: string; // Virtual, not to be used on insert
}

export const emptyGuid = '00000000-0000-0000-0000-000000000000';

export enum AlertType {
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info',
}

export class AlertTypeOrder {
  static readonly [AlertType.Danger] = 0;
  static readonly [AlertType.Warning] = 1;
  static readonly [AlertType.Info] = 2;
}

export class Alert {
  constructor(
    type: AlertType,
    name: string,
    id: string = '',
    description: string = ''
  ) {
    this.type = type;
    this.name = name;
    this.id = id;
    this.description = description;
  }

  type: AlertType;
  name: string;
  description: string;
  id?: string;
}

export enum ToastStyle {
  None = 'none',
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
}

export class Toast {
  constructor(
    type: { style: ToastStyle; icon: boolean },
    closable: boolean,
    content: string,
    id: string
  ) {
    this.type = type;
    this.closable = closable;
    this.content = content;
    this.id = id;
  }
  type: { style: ToastStyle; icon: boolean };
  closable: boolean;
  content: string;
  id: string;
}

export enum ColSize {
  OneTwelfth = 1,
  OneSixth = 2,
  OneFourth = 3,
  OneThird = 4,
  Half = 6,
  TwoThirds = 8,
  ThreeFourths = 9,
  FiveSixths = 10,
  Full = 12,
  Auto = 'auto',
}

export class ColumnConfig {
  constructor(x: ColumnConfig) {
    Object.assign(this, x);
  }

  xs?: number | string;
  sm?: number | string;
  md?: number | string;
  lg?: number | string;
  xl?: number | string;
}

export const ColumnLayout = {
  XXS: new ColumnConfig({
    xs: ColSize.Full,
    md: ColSize.OneSixth,
    lg: ColSize.OneTwelfth,
  }),
  XS: new ColumnConfig({
    xs: ColSize.Full,
    md: ColSize.OneFourth,
    lg: ColSize.OneSixth,
  }),
  Small: new ColumnConfig({
    xs: ColSize.Full,
    md: ColSize.OneThird,
    lg: ColSize.OneFourth,
  }),
  Med: new ColumnConfig({ xs: ColSize.Full, md: ColSize.OneThird }),
  Large: new ColumnConfig({ xs: ColSize.Full, xl: ColSize.Half }),
  XL: new ColumnConfig({ xs: ColSize.TwoThirds }),
  XXL: new ColumnConfig({ xs: ColSize.ThreeFourths }),
  Full: new ColumnConfig({ xs: ColSize.Full }),
  Auto: new ColumnConfig({ xs: ColSize.Auto }),
};

export enum Position {
  Center = 'center',
  Left = 'left',
  Right = 'right',
  TopCenter = 'topCenter',
  TopLeft = 'topLeft',
  TopRight = 'topRight',
  BottomCenter = 'bottomCenter',
  BottomLeft = 'bottomLeft',
  BottomRight = 'bottomRight',
}

export enum ColorTheme {
  Light = 'light',
  Dark = 'dark',
}

export enum ToastType {
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
  Info = 'info',
}

export type NamedCoreEnum = {
  [x: string]: CoreEnum;
};

export class CoreEnum {
  value?: string | number;
  displayName?: string;
  [x: string]: any;
}

export type Enumerations = {
  entityEventType: NamedCoreEnum;
  gender: NamedCoreEnum;
  physicianSpeciality: NamedCoreEnum;
  referralType: NamedCoreEnum;
  urType: NamedCoreEnum;
  state: NamedCoreEnum;
  evaluation: NamedCoreEnum;
  firstLevelReviewStatus: NamedCoreEnum;
  appointmentStatus: NamedCoreEnum;
  urLevel: NamedCoreEnum;
  autoProcessStatus: NamedCoreEnum;
  natureOfEval: NamedCoreEnum;
  drugStatus: NamedCoreEnum;
  followUpTimeFrame: NamedCoreEnum;
  irRatingUnit: NamedCoreEnum;
  guidelinesToUse: NamedCoreEnum;
  appointmentReportContact: NamedCoreEnum;
  appointmentReportStatus: NamedCoreEnum;
  appointmentReportSendTo: NamedCoreEnum;
  appointmentReportSendToSecond: NamedCoreEnum;
  appointmentReportQaStatus: NamedCoreEnum;
  initialIrExamType: NamedCoreEnum;
  ReferralsRequestStatus: NamedCoreEnum;
  evaluationType: NamedCoreEnum;
  reviewerStatus: NamedCoreEnum;
  licenseType: NamedCoreEnum;
  contactMethod: NamedCoreEnum;
  productType: NamedCoreEnum;
  outcomeOfReview: NamedCoreEnum;
  appointmentReportDetermination: NamedCoreEnum;
  appointmentReportSourceCriteria: NamedCoreEnum;
  caseStatus: NamedCoreEnum;
  contactType: NamedCoreEnum;
  imeStatus: NamedCoreEnum;
  reportReviewStatus: NamedCoreEnum;
  timeFrame: NamedCoreEnum;
  apContact: NamedCoreEnum;
  referralRequestType: NamedCoreEnum;
  tatPriority: NamedCoreEnum;
  apContactPreference: NamedCoreEnum;
  specialty: NamedCoreEnum;
  lineOfBusiness: NamedCoreEnum;
  referralsRequestDraftStatus: NamedCoreEnum;
};

export enum AsyncStatus {
  Idle = 'idle',
  Pending = 'pending',
  Success = 'success',
  Error = 'error',
  Cancelled = 'cancelled',
}

export class PersistentObjectModel {
  id: string = EMPTY_GUID;
}

export class BoolDropdownItemModel {
  text?: string;
  value?: boolean;
}

export class DropdownItemModel {
  name?: string;
  id?: string;
}

export class DateDropdownItemModel {
  name?: string;
  dateTime?: Date;
}

export enum Keys {
  Backspace = 'Backspace',
  Delete = 'Delete',
  UpArrow = 'ArrowUp',
  DownArrow = 'ArrowDown',
  Enter = 'Enter',
  A = 'a',
  P = 'p',
  Tab = 'Tab',
  Space = ' ',
  T = 't',
  Y = 'y',
}

export class CaseActionCodeModel {
  actionCode?: DropdownItemModel;
  proceedingsText?: string;
  caseId?: string;
  entityId?: string;
}
export class DynamicViewNavItem {
  constructor(item: DropdownItemModel) {
    this.label = item.name ?? '';
    this.route = '/' + item.id;
    this.fullRoute = '/' + item.id;
  }
  label: string;
  route: string;
  fullRoute: string;
}
