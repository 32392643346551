import React from "react";
import './AddEditReferralsRequest.AttorneyInformation.scss'
import ReadOnlyField from "v3/Components/Form/ReadOnlyField";
import { TitleReadOnly } from "v3/Components/Form/TitleReadOnly";
import { InputActive } from "v3/Components/Form/InputActive";
import { FieldError } from "v3/Components/Form/FormSection";
import { ReferralRequestSchema, useReferralRequestForm } from './useReferralRequestForm';
import { useIdParam } from '../../../../Utilities';
import { ReplicableComponent } from '../../Form/ReplicableComponent';

type AttorneyInformationProps = {
  form: ReturnType<typeof useReferralRequestForm>;
  fieldErrors?: FieldError[];
}

export const AttorneyInformationActive: React.FC<AttorneyInformationProps> = ({ 
                                                                                form, 
                                                                                fieldErrors
                                                                          }) => {
  const [id, isAdd, isEdit] = useIdParam();
  
  return (
    <div className="contact-row">
      <ReplicableComponent
        form={form}
        initialData={{ attorneyName: '', attorneyPrimaryContact: '', attorneyAdditionalContact: '' }}
        label="Attorney Information"
        formPropsToClearOnRemoval={['attorneys']}
      >
        {({ index, data, onChange, onDuplicate }) => (
          <div className="contact-fields">
            <InputActive
              id="attorneyName"
              name="Attorney Name"
              label="Attorney Name"
              value={form.getFieldProps("attorneys").value?.[index]?.attorneyName ?? ""}
              onChange={(e: any) => onChange({ attorneyName: e.target.value }, "attorneys")}
              style={{ width: '33%', marginBottom: '5px' }}
              error={fieldErrors?.find(f => f.fieldId === "attorneyName")?.error}
            />
            <InputActive
              id="attorneyPrimaryContact"
              name="attorneyPrimaryContact"
              label="Primary Contact (Phone or Email)"
              value={form.getFieldProps("attorneys").value?.[index]?.attorneyPrimaryContact ?? ""}
              onChange={(e: any) => onChange({ attorneyPrimaryContact: e.target.value }, "attorneys")}
              style={{ width: '33%' }}
              error={fieldErrors?.find(f => f.fieldId === "attorneyPrimaryContact")?.error}
            />
            <InputActive
              id="attorneyAdditionalContact"
              label="Additional Contact (Phone or Email)"
              name="attorneyAdditionalContact"
              value={form.getFieldProps("attorneys").value?.[index]?.attorneyAdditionalContact ?? ""}
              onChange={(e: any) => onChange({ attorneyAdditionalContact: e.target.value }, "attorneys")}
              style={{ width: '33%' }}
              error={fieldErrors?.find(f => f.fieldId === "attorneyAdditionalContact")?.error}
            />
          </div>
        )}
      </ReplicableComponent>
    </div>
      );
      }

      export const AttorneyInformationReadOnly: React.FC<AttorneyInformationProps> = ({
      form
    }) => {

      return (
      <div className="read-only-content">
      <TitleReadOnly>Attorney Name</TitleReadOnly>
        <ReadOnlyField
          id="attorneyName"
          schema={ReferralRequestSchema}
          label="Attorney Name"
          value={form.getFieldProps("attorneyName").value ?? "" } />
      <TitleReadOnly>Primary Contract</TitleReadOnly>
        <ReadOnlyField
          id="attorneyPrimaryContact"
          schema={ReferralRequestSchema}
          label="Primary Contact"
          value={form.getFieldProps("attorneyPrimaryContact").value ?? ""} />
      <TitleReadOnly>Additional Contact</TitleReadOnly>
        <ReadOnlyField
          id="attorneyAdditionalContact"
          schema={ReferralRequestSchema}
          label="Additional Contact"
          value={form.getFieldProps("attorneyAdditionalContact").value ?? ""} />
    </div>
  )
}