import { ReferralsRequest } from 'v3/Models/ReferralRequest';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { first } from 'lodash';

export const DEFAULT_REFERRAL_REQUEST: ReferralsRequest = {
  id: '',
  skipValidation: true,
  referralSourceId: '',
  referralDate: new Date(),
  firstName: '',
  lastName: '',
  streetAddress: '',
  city: '',
  state: '',
  zipCode: '',
  phoneNumber: '',
  email: '',
  specialInstructions: '',
  referralRequestDraftDocuments: [],
  dateOfBirth: new Date(),
  dueDate: new Date(),
  referralNumber: '',
  dateOfInjury: new Date(),
  dateOfRequest: new Date(),
  dateOfRequestDuplicate: new Date(),
  dateOfInjuryOrDisability: new Date(),
  employerName: '',
  employerLocation: '',
  employerZipCode: '',
  medicationName: '',
  medicationDetails: '',
  tat: first([]),
  tatPriority: first([]),
  clientNumber: '',
  juristictionalMatch: first([]),
  benefitState: first([]),
  requestedReviewerSpecialty: first([]),
  service: first([]),
  subService: first([]),
  apContact: first([]),
  apName: '',
  apSpecialty: first([]),
  apPhoneNumber: '',
  apFaxOrEmail: '',
  attorneyName: '',
  attorneyPrimaryContact: '',
  attorneyAdditionalContact: ''
};

type UseReferralRequestFormProps = {
  onSubmit: (values: ReferralsRequest) => void;
  referralRequest?: ReferralsRequest | null;
};

export const useReferralRequestForm = ({
  onSubmit,
  referralRequest,
}: UseReferralRequestFormProps) => {
  const formik = useFormik<ReferralsRequest>({
    enableReinitialize: true,
    initialValues: referralRequest ?? DEFAULT_REFERRAL_REQUEST,
    onSubmit: onSubmit,
    validateOnMount: true,
  });

  return formik;
};

const AddEditReferralRequestSections = {
  AdditionalInformation: 'Additional Information',
  ClaimantInformation: 'Claimant Information',
  ReportDetails: 'Report Details',
  AttorneysInformation: 'Attorneys Information'
}

export const ReferralRequestSchema = Yup.object().shape({
  specialInstructions: Yup.bool().meta({
    label: 'Comments',
    section: AddEditReferralRequestSections.AdditionalInformation,
  }),
  firstName: Yup.string().nullable().meta({
    label: 'First Name',
    section: AddEditReferralRequestSections.ClaimantInformation,
  }),
  lastName: Yup.string().nullable().meta({
    label: 'Last Name',
    section: AddEditReferralRequestSections.ClaimantInformation,
  }),
  streetAddress: Yup.string().nullable().meta({
    label: 'Street Address',
    section: AddEditReferralRequestSections.ClaimantInformation,
  }),
  city: Yup.string().nullable().meta({
    label: 'City',
    section: AddEditReferralRequestSections.ClaimantInformation,
  }),
  zipCode: Yup.string().nullable().meta({
    label: 'Zip Code',
    section: AddEditReferralRequestSections.ClaimantInformation,
  }),
  phoneNumber: Yup.string().nullable().meta({
    label: 'Phone Number',
    section: AddEditReferralRequestSections.ClaimantInformation,
  }),
  email: Yup.string().nullable().meta({
    label: 'Email',
    section: AddEditReferralRequestSections.ClaimantInformation,
  }),
  state: Yup.object().nullable().meta({
    label: 'State',
    section: AddEditReferralRequestSections.ClaimantInformation,
  }),
  gender: Yup.object().nullable().meta({
    label: 'Gender',
    section: AddEditReferralRequestSections.ClaimantInformation,
  }),
  dateOfBirth: Yup.date().nullable().meta({
    label: 'Date of Birth',
    section: AddEditReferralRequestSections.ClaimantInformation,
  }),
  dueDate: Yup.date().nullable().meta({
    label: "Due Date",
    section: AddEditReferralRequestSections.ReportDetails
  }),
  clientNumber: Yup.string().nullable().meta({
    label: 'Claim/Client Number',
    section: AddEditReferralRequestSections.AdditionalInformation,
  }),
  referralNumber: Yup.string().nullable().meta({
    label: 'Referral Number',
    section: AddEditReferralRequestSections.AdditionalInformation,
  }),
  dateOfInjury: Yup.date().nullable().meta({
    label: 'Date of Injury',
    section: AddEditReferralRequestSections.AdditionalInformation,
  }),
  dateOfRequest: Yup.date().nullable().meta({
    label: 'Date of Request',
    section: AddEditReferralRequestSections.AdditionalInformation,
  }),
  dateOfRequestDuplicate: Yup.date().nullable().meta({
    label: 'Date of Request',
    section: AddEditReferralRequestSections.AdditionalInformation,
  }),
  dateOfInjuryOrDisability: Yup.date().nullable().meta({
    label: 'Date of Injury/Disability',
    section: AddEditReferralRequestSections.AdditionalInformation,
  }),
  employerName: Yup.string().nullable().meta({
    label: 'Employer Name',
    section: AddEditReferralRequestSections.AdditionalInformation,
  }),
  employerLocation: Yup.object().nullable().meta({
    label: 'Employer Location',
    section: AddEditReferralRequestSections.AdditionalInformation,
  }),
  employerZipCode: Yup.string().nullable().meta({
    label: 'Employer Zip Code',
    section: AddEditReferralRequestSections.AdditionalInformation,
  }),
  medicationName: Yup.string().nullable().meta({
    label: 'Medication Name',
    section: AddEditReferralRequestSections.AdditionalInformation,
  }),
  medicationDetails: Yup.string().nullable().meta({
    label: 'Medication Details',
    section: AddEditReferralRequestSections.AdditionalInformation,
  }),
  tat: Yup.object().nullable().meta({
    label: 'TAT',
    section: AddEditReferralRequestSections.AdditionalInformation,
  }),
  tatPriority: Yup.object().nullable().meta({
    label: 'TAT Priority',
    section: AddEditReferralRequestSections.AdditionalInformation,
  }),
  jurisdictionalMatch: Yup.object().nullable().meta({
    label: 'Jurisdictional Match Required',
    section: AddEditReferralRequestSections.AdditionalInformation,
  }),
  benefitState: Yup.object().nullable().meta({
    label: 'Benefit State',
    section: AddEditReferralRequestSections.AdditionalInformation,
  }),
  requestedReviewerSpecialty: Yup.object().nullable().meta({
    label: 'Requested Reviewer Specialty',
    section: AddEditReferralRequestSections.AdditionalInformation,
  }),
  service: Yup.object().nullable().meta({
    label: 'Service',
    section: AddEditReferralRequestSections.AdditionalInformation,
  }),
  subService: Yup.object().nullable().meta({
    label: 'Sub Service',
    section: AddEditReferralRequestSections.AdditionalInformation,
  }),
  apContact: Yup.object().nullable().meta({
    label: 'AP Contact',
    section: AddEditReferralRequestSections.AdditionalInformation,
  }),
  apName: Yup.string().nullable().meta({
    label: 'AP Name',
    section: AddEditReferralRequestSections.AdditionalInformation,
  }),
  apSpecialty: Yup.object().nullable().meta({
    label: 'AP Specialty',
    section: AddEditReferralRequestSections.AdditionalInformation,
  }),
  apPhoneNumber: Yup.string().nullable().meta({
    label: 'AP Phone Number',
    section: AddEditReferralRequestSections.AdditionalInformation,
  }),
  apFaxOrEmail: Yup.string().nullable().meta({
    label: 'AP Fax or Email',
    section: AddEditReferralRequestSections.AdditionalInformation,
  }),
  attorneys: Yup.array().of(
    Yup.object().shape({
      attorneyName: Yup.string().required(),
      attorneyPrimaryContact: Yup.string().required(),
      attorneyAdditionalContact: Yup.string().required()
    })
  ).meta({
    label: 'Attorneys',
    section: AddEditReferralRequestSections.AttorneysInformation
  }),
});
