import { useFormik } from "formik";
import { Client } from "../../../Models/Client";
import * as Yup from "yup";

export const DEFAULT_CLIENT: Client = {
  id: "",
  name: "",
  contactEmail: "",
  isActive: true,
  clientPrimaryContacts: [
    {
      primaryContactName: "",
      primaryContactPhone: "",
      primaryContactEmail: ""
    }
  ],
  clientBillingContacts: [
    {
      billingContactName: "",
      billingContactPhone: "",
      billingContactEmail: ""
    }
  ],
  clientContracts: [
  ]
};

const AddEditClientSections = {
  ClientInformation: "Client Information",
  ContactInformation: "Contact Information",
  ClientSetUp: "Client Set-up",
}

export const ClientSchema = Yup.object().shape({
  name: Yup.string().nullable().meta({ 
    label: "Client Name",
    section: AddEditClientSections.ClientInformation
  }),
  contactEmail: Yup.string().nullable().meta({ 
    label: "CC Notification Email",
    section: AddEditClientSections.ClientInformation
  }),
  isActive: Yup.bool().meta({ 
    label: "Status",
    section: AddEditClientSections.ClientInformation
  }),
  clientPrimaryContacts: Yup.array().nullable().of(
    Yup.object().shape({
      primaryContactName: Yup.string().meta({ 
        label: "Primary Contact Name",
        section: AddEditClientSections.ContactInformation
      }),
      primaryContactPhone: Yup.string().meta({ 
        label: "Primary Contact Phone",
        section: AddEditClientSections.ContactInformation
      }),
      primaryContactEmail: Yup.string().meta({ 
        label: "Primary Contact Email",
        section: AddEditClientSections.ContactInformation
      }),
    })
  ).meta({ 
    section: AddEditClientSections.ContactInformation 
  }),
  clientBillingContacts: Yup.array().nullable().of(
    Yup.object().shape({
      billingContactName: Yup.string().meta({ 
        label: "Billing Contact Name",
        section: AddEditClientSections.ContactInformation
      }),
      billingContactPhone: Yup.string().meta({ 
        label: "Billing Contact Phone",
        section: AddEditClientSections.ContactInformation
      }),
      billingContactEmail: Yup.string().meta({ 
        label: "Billing Contact Email",
        section: AddEditClientSections.ContactInformation
      }),
    })
  ).meta({ 
    section: AddEditClientSections.ContactInformation 
  }),
  lineOfBusinesses: Yup.array().nullable().of(
    Yup.object().shape({
      lineOfBusiness: Yup.object().shape({
        value: Yup.string().nullable(),
        displayName: Yup.string().nullable()
      }).meta({ 
        label: "Line of Business",
        section: AddEditClientSections.ClientSetUp
      }),
      service: Yup.object().shape({
        id: Yup.string().nullable(),
        name: Yup.string().nullable()
      }).meta({ 
        label: "Service",
        section: AddEditClientSections.ClientSetUp
      }),
      subService: Yup.object().shape({
        id: Yup.string().nullable(),
        name: Yup.string().nullable()
      }).meta({ 
        label: "Sub-Service",
        section: AddEditClientSections.ClientSetUp
      }),
      reportQuestions: Yup.array().nullable().of(
        Yup.object().shape({
          question: Yup.string().nullable().meta({ 
            label: "Question",
            section: AddEditClientSections.ClientSetUp
          }),
          answer: Yup.string().nullable().meta({ 
            label: "Answer",
            section: AddEditClientSections.ClientSetUp
          }),
          isSelected: Yup.boolean().nullable().meta({ 
            label: "Selected",
            section: AddEditClientSections.ClientSetUp
          })
        })
      ).meta({ 
        section: AddEditClientSections.ClientSetUp
      })
    })
  ).meta({
    section: AddEditClientSections.ClientSetUp
  })
});

type UseClientFormProps = {
  onSubmit: (values: Client) => void;
  client?: Client | null;
};

export const useClientForm = ({
  onSubmit,
  client,
}: UseClientFormProps) => {
  const formik = useFormik<Client>({
    enableReinitialize: true,
    initialValues: client ?? DEFAULT_CLIENT,
    validationSchema: ClientSchema,
    onSubmit: onSubmit,
    validateOnMount: true
  });
  
  return formik;
};