import React, { useState, useEffect, useRef } from 'react';
import './MultiSelectDropdown.scss';
import arrowUp from 'Assets/arrow_up.svg';
import arrowDown from 'Assets/arrow_down.svg';
import { NamedCoreEnum } from 'Models';

type DropdownValue = string | number | undefined;
type DropdownDisplayName = string | number | undefined;
type DropdownOption = {
  value: DropdownValue;
  displayName: DropdownDisplayName;
  id?: string;
  name?: string;
};
type DropdownDbOption = {
  id: string;
  name: string;
}

interface Option {
  value: DropdownValue;
  label: DropdownDisplayName;
}

interface MultiSelectDropdownProps {
  label: DropdownDisplayName;
  hint?: string;
  values?: DropdownOption[];
  onChange: (options?: DropdownOption[]) => void;
  options?: DropdownOption[];
  required?: boolean;
  enums?: NamedCoreEnum;
  valueAsDisplayName?: boolean;
  dbOptions?: DropdownDbOption[];
}

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  label,
  hint,
  values = [],
  onChange,
  options,
  required,
  enums,
  valueAsDisplayName: valueAsLabel,
  dbOptions,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);

  if (enums) {
    options = Object.keys(enums).map((key) => ({
      value: enums[key]?.value,
      displayName: valueAsLabel ? enums[key]?.value : enums[key]?.displayName,
    }));
  }
  else if (dbOptions) {
    options = dbOptions.map((option) => ({
      value: option.id,
      displayName: option.name,
      id: option.id,
      name: option.name,
    }));
    values = values.map((value) => ({
      value: value.id,
      displayName: value.name,
      id: value.id,
      name: value.name,
    }));
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option: DropdownOption) => {
    const currentValues = values ?? [];
    const isSelected = currentValues.some((value) => value.value === option.value);
    const newValues = isSelected
      ? currentValues.filter((value) => value.value !== option.value)
      : [...currentValues, option];
    onChange(newValues);
  };

  const handleSelectAll = () => {
    if (values?.length === options?.length) {
      onChange([]);
    } else {
      onChange(options);
    }
  };

  return (
    <div className="dropdown-container">
      <label>
        {label} {required ? <span className="required">*</span> : '(Optional)'}
      </label>

      <div
        ref={inputRef}
        onClick={handleToggle}
        className="dropdown-input-container"
        tabIndex={0}
      >
        <span>
          {values?.length > 0
            ? values.map((value) => value.displayName).join(', ')
            : '--'}
        </span>
        <img
          src={isOpen ? arrowUp : arrowDown}
          alt={isOpen ? 'Arrow Up' : 'Arrow Down'}
        />
      </div>

      <div ref={dropdownRef} className={`dropdown ${isOpen ? 'open' : ''}`}>
        <div
          className="multi-select-dropdown-option-item"
          onClick={handleSelectAll}
        >
          <input
            type="checkbox"
            checked={values?.length === options?.length}
            readOnly
          />
          Select All
        </div>
        {options?.map((option) => (
          <div
            key={option.value}
            className={`multi-select-dropdown-option-item ${values?.some((value) => value.value === option.value)
                ? 'selected'
                : ''
              }`}
            onClick={() => handleSelect(option)}
          >
            <input
              type="checkbox"
              checked={values?.some((value) => value.value === option.value)}
              readOnly
            />
            {option.displayName}
          </div>
        ))}
      </div>
      {hint && <div className="hint">{hint}</div>}
    </div>
  );
};

export default MultiSelectDropdown;