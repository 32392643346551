import { DropdownDbOption, DropdownOption } from "v3/Components/Form/Dropdown";

export class Client {
  id?: string;
  name?: string;
  contactEmail?: string;
  isActive?: boolean;
  clientPrimaryContacts?: PrimaryContact[];
  clientBillingContacts?: BillingContact[];
  clientContracts?: Contract[];
  lineOfBusinesses?: LineOfBusinesses[];
}

export type LineOfBusinesses = {
  id?: string;
  lineOfBusiness?: DropdownOption;
  service?: DropdownDbOption;
  subService?: DropdownDbOption;
  reportQuestions?: reportQuestion[];
}

export type reportQuestion = {
  id?: string;
  question?: string;
  answer?: string;
  isSelected?: boolean;
}

export type Contract = {
  fileId: string;
  fileName: string;
  size: string;
}

export type PrimaryContact = {
  primaryContactName: string;
  primaryContactPhone: string;
  primaryContactEmail: string;
}

export type BillingContact = {
  billingContactName: string;
  billingContactPhone: string;
  billingContactEmail: string;
}